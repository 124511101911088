.full-spinner-container {
    background-color: gray;
    z-index: 1111;
    opacity: 0.6;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .full-spinner-box {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .connlost{
    
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 20%;
    margin-top: 12%;
    color: #fff;
    background: red;
    font-size: 2em;
    z-index:1;
    text-align:center;
}