html, body {
  margin: 0;
  font-family: 'Nunito', sans-serif !important;
}

body {
  background-color: #17bebb55 !important;
}

.btn-orange{
  background-color: #fd6e00;
  color:#fff;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.landing-banner{
  height: 23vh;
  padding-top: 10vh;
}

.landing-buttons{
  height:30vh;
  padding-top:5vh;
  background-color: #fff;
}
.login-form{
background-color: #fff;
padding: 50px 0;
}
.footer-container{

  padding-top: 60px;
  background-color: #fff;
}
footer{
  /* position: absolute; */
  bottom:0px;
  left: 0px;
  font-size: .9em;
  text-align: center;
  color: #fff;
}
footer p {
  margin: 0 !important;
}
.row-spacing{
  padding-top: 40px;
  padding-bottom: 40px;
}
.customfield-editable{
  display: inline-block;
  margin-left: 12px;
}
.customfield-original{
  font-size: .9em;
  color: #777;
}
.tbl-btn-icon{
  margin-right: 5px;
}

.partecipantsTablexx table {
  width: 200%;
  min-width: unset !important;
}
.partecipantsTablexx table td {
  width: auto;
  min-width: unset !important;
}


li.ant-picker-ok {
  width: 100% !important;    
}

li.ant-picker-ok>button{
  width: 100% !important;    
}
.ant-picker-time-panel-cell-inner{
  font-size:1.5em;
   height:50px !important;
 }

 .partecipantsales-aggregate{
  width:50%;
  margin-top:40px;
}

.activitiesbtn > span > button{
  margin-right: 10px;
  margin-bottom: 10px;
  min-width: 160px;
}

.ant-tabs-tab-btn {
  /* font-stretch: expanded; */
  font-size: 1.3em;
  font-weight: bold;
  padding: 15px 0px;
  /* letter-spacing: 3px; */
}
.spp-name{
  font-size: 1.2em;
  font-weight: bold;
  padding: 15px;
  text-transform: uppercase;
}

.spp-pct-showtimeonly{
  font-size: 1.3em;
  font-weight: bold;
  padding: 15px 0;
  text-transform: uppercase;
  display: inline-block;
}
.spp-pn-text{
  margin-bottom: 15px !important;
}
.ant-table-body > table > tbody > tr:nth-child(n) > td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last > span {
  text-transform: uppercase;  
}

.ant-table-body > table > tbody > tr:nth-child(n) > td:nth-child(1) {
  text-transform: uppercase;  
}


.refunded-line{
  text-decoration: line-through !important;
}

.ant-modal{
  top:10px !important;
}

.spp-table{
  width: 100%;
  font-size: 1.2em;
}
.spp-table td{
padding:20px
}

/*  Define the background color for all the ODD background rows  */
.spp-panel .row-odd{ 
  background: #fff !important;
}
/*  Define the background color for all the EVEN background rows  */
.spp-panel .row-even{
  background: #eee !important;
}

.spp-panel > div:nth-child(1) {
  font-size: 1.1em;
  letter-spacing: 1px;
  background-color: #e6f7ff;
  font-weight:600;
}

.spp-collapse{
  margin-bottom:10px !important;
  padding:10px;
}
.spp-status{
  color:#000;
}
.ant-collapse-content-box {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.editNoteIconBtn {
  margin-left: 25px;
}
.activitiesbtn {
  border: 1px solid #ddd !important;
  margin: 0 0 10px 0 !important;
  padding: 10px !important;
  padding-bottom: 0 !important;
  background-color: #fff !important;
}

.spp-panel .row-odd .activitiesbtn {
  background: #eee !important;
}
.activitiesbtn button.btn-light {
  border: 1px solid #ddd !important;
  outline: none !important;
}
.spp-pct-bntnow{
  min-width:80px;
  margin: 10px;
}
.entered-at {
  background-color: #28a745ba !important;
  font-weight: bold !important;
}
.exited-at {
  background-color: #ffc107ba !important;
  font-weight: bold !important;
}
.ant-modal.modal-partecipant .ant-modal-body {
  padding-bottom: 0!important;
}
.input-filter-partecipants,
.input-filter-days {
  margin-left:5px !important;
  border-color: #cacaca !important;
  box-shadow: 0 0 5px rgba(0,0,0,.24);
}
.footer-container {
  width: 100% !important;
  margin: 0 !important;
}

.footer-container > div {
  padding: 0 !important;
}
footer {
  background-color: #17bebb55;
  padding:10px;
}
.is-today .ant-collapse-header {
  background-color: #17bebb ! important;
  color:#fff !important;
}
.is-today .ant-collapse-header span {
  color:#fff !important;
}

.modal-partecipant label {
  margin-bottom: 0 !important;
}
.modal-partecipant .ant-form-item-label {
  overflow: auto !important;
}

@media screen and (max-width: 800px) {
  .modal-partecipant {
    width:100%!important;
  }
  .modal-partecipant .ant-form-item-label {
    padding:0 !important;
  }
}

@media screen and (max-width: 599px) {
  .landing-buttons .btn {
    width: 300px !important;
  }

  .landing-banner {
    height: 20vh;
    padding-top: 8vh;
  }
}